<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
        <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="560"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <v-form v-model="emailValid" @submit="sendResetMail">
                    <h1>Esqueceu sua senha?</h1>
                    <p>Insira seu endereço de e-mail. Caso ele exista em nosso sistema, enviaremos nele as intruções para resetar sua senha.</p>
                    <v-text-field dense label="E-Mail" class="mb-2" outlined v-model="email" :rules="[rules.required, rules.email]"></v-text-field>
                    <v-btn block color="primary" @click="sendResetMail" :disabled="!emailValid">
                      Enviar e-mail de reset
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">Quero voltar!</p>
                  <a @click="navigateToSigin"> login </a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import formMixin from '@/util/mixins/formMixin'
export default {
  name: 'ForgotPasswordScreen',

  mixins: [formMixin],

  data() {
    return {
      email: null,
      emailValid: null,
    }
  },

  methods: {
    sendResetMail() {
      this.$http.$post('/auth/forgot-password', {email: this.email})
      .then(response => {
        console.log(response)
        this.$router.push('/login')
      })
      .catch(error => {
        console.log(error)
      })
    },

    navigateToSigin(){
      this.$router.push('/login')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
